<template>
<v-app-bar
      app
      color="white"
      dark
      elevate-on-scroll
    >
    <v-app-bar-nav-icon
      class="hidden-md-and-up"
      @click="toggleDrawer"
    />
      <v-container fluid class="mx-auto py-0">
      <v-row align="center">
        
        <v-img
          :src="require('@/assets/EFAM.png')"
          class="mr-9 ml-15"
          contain
          height="120"
          width="120"
          max-width="120"
          @click="$vuetify.goTo(0)"
        />

        <v-btn
          v-bind="link"
          class="hidden-sm-and-down"
          text
          color="black"
          href="/"
        >
          Accueil
        </v-btn>
        
        <v-btn
          v-bind="link"
          class="hidden-sm-and-down"
          text
          color="black"
         
          @click="$vuetify.goTo('#footer')"
        >
          Contact
        </v-btn>

         <!-- <v-app-bar-nav-icon color="black" @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
      </v-row>
    </v-container>
    </v-app-bar>
 


    <!-- <v-navigation-drawer
      v-model="drawer"
      mini-variant="mini"
      absolute
      bottom
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title>Foo</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Bar</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Fizz</v-list-item-title>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>Buzz</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer> -->

   
</template>

<script>
    import {
        mapGetters,
        mapMutations,
    } from 'vuex';
    export default {
        name: 'Navbar-formation',
        

    data: () => ({
        //
        drawer: false,
        group: null,
    }),
    watch: {
        group () {
            this.drawer = false
        },
        },
        computed: {
        
        ...mapGetters(['links']),
        },
        methods: {
        ...mapMutations(['toggleDrawer']),
        onClick (e, item) {
            e.stopPropagation()

            if (item.to || !item.href) return

            this.$vuetify.goTo(item.href.endsWith('!') ? 0 : item.href)
        },
        },
    }

</script>